
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'


const dataBox = [

    {
        id: 1,
        img: img3,
        title: 'Bid Data Collections',
        desc: 'Social media profiles, keywords/hashtags, sentences, Geo fencing areas, specific language contents, web site, customize your wishes'
    },
    {
        id: 2,
        img: img2,
        title: 'Big Data Analytics using AI & Machine Learning',
        desc: 'Multiple analytics functionalities. We have 10+ years experience with AI & Machine Learning'
    },
    {
        id: 3,
        img: img1,
        title: 'Realtime',
        desc: 'Alarms and notifications on time to your device. You can improve your business in no time by giving you real-time information about you and your community.'
    },
    {
        id: 4,
        img: img4,
        title: 'Natural language processing (NLP) and (ML)',
        desc: 'Ikhnuden`s pretrained Machine Learning (ML) and Artificial Monitoring (AI) that helps you make a decisions'
    },

]

export default dataBox;