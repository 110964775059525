import React , {useState} from 'react';

import img1 from '../../assets/images/layouts/about-01.png'
import img2 from '../../assets/images/layouts/about-02.png'
import img3 from '../../assets/images/layouts/about-03.png'
import img4 from '../../assets/images/layouts/about-04.png'
import img5 from '../../assets/images/layouts/about-05.png'
import Button from '../button/Button';



function About(props) {

    const [dataImg] = useState([
        {
            id: 1,
            img : img1,
            class: 'img1'
        },
        {
            id: 2,
            img : img2,
            class: 'img2'
        },
        {
            id: 3,
            img : img3,
            class: 'img3'
        },
        {
            id: 4,
            img : img4,
            class: 'img4'
        },
        {
            id: 5,
            img : img5,
            class: 'img5'
        },
    ])

    const [dataBlock] = useState(
        {
            subheading: 'About us',
            heading: 'Manage your marketing strategy with high-level overviews',
            desc1: 'Get the visibility you need to monitor all of your ongoing campaigns so you can make strategic decisions about your company`s goals.' ,
            desc2 :'IKHNUDEN® can give you an instant overview of millions of online conversations in real time. Search for any topic or keyword, and filter by date, demographics, location, and more. You’ll be able to identify thought leaders or brand advocates, understand the perception of your brand in the market, and get immediate alerts if and when your mentions spike (for good or for bad.)'

        }
    )
    return (
        <section className="about">
                <div className="shape"></div>
                <div className="container">
                    <div className="row rev">
                        <div className="col-xl-6 col-md-12">
                            <div className="about__right">
                                <div className="images">
                                    {
                                        dataImg.map(idx => (
                                            <img key={idx.id} className={idx.class} src={idx.img} alt="IKHNUDEN" />
                                        ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="block-text" >
                                <h6 className="sub-heading"><span>{dataBlock.subheading}</span></h6>
                                <h3 className="heading">{dataBlock.heading}</h3>
                                    <h6 className="mb-17">{dataBlock.desc1}</h6>
                                    <h6 className="mb-26">{dataBlock.desc2}</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default About;