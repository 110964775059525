import React from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/layouts/banner.png'
import img2 from '../../assets/images/icon/icon-01.png'
import img3 from '../../assets/images/layouts/avt-01.png'
import img4 from '../../assets/images/layouts/about-03.png'

function Banner(props) {
    const handleClick = () => {
        window.location.href = 'http://admin.ikhnuden.mn';
    };

    return (
        <section className="banner">
                <div className="shape right"></div>
                <div className="container big">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="banner__left">
                                <div className="block-text">
                                    <h2 className="heading">
                                        <span className="s1 arlo_tm_animation_text_word">IKHNUDEN</span> <br />
                                        Digital Marketing Service</h2>
                                        <p className='desc'>
                                            <h6>The most performing platform to gather and analyse Digital data from Social Media, Web, Forums. IKHNUDEN® is an end-to-end Social Media Monitoring and OSINT product that transforms public data such as Social Media in valuable information.</h6>
                                        </p>
                                    <Link className="action-btn" onClick={handleClick}><span>Get connected</span></Link>
                                </div>

                                <div className="pay">
                                    <h6>Supports public and private organisations to understand the framework, to take decisions and to promptly react to threats.</h6>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">

                            <div className="banner__right">
                                <div className="image">
                                    <img src={img4} alt="IKHNUDEN" />
                                </div>

                                <div className="price">
                                    <div className="icon">
                                        <img src={img2} alt="IKHNUDEN" />
                                    </div>
                                    <div className="content">
                                        <h5>What we do?</h5>
                                        <p>Big Data Analysis, Social Media Monitoring and OSINT</p>
                                    </div>
                                </div>

                                <div className="owner">
                                    <div className="icon">
                                        <img src={img2} alt="IKHNUDEN" />
                                    </div>
                                    <div className="content">
                                        <h5>What we do differently?</h5>
                                        <p>AI, ML Analytics, Virtual Humint Ops and Data Enrichment from Various Sources</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    );
}

export default Banner;
