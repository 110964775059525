
import img1 from '../images/svg/icon-1.svg'
import img2 from '../images/svg/icon-2.svg'
import img3 from '../images/svg/icon-3.svg'
import img4 from '../images/svg/icon-4.svg'



const dataPortfolio = [

    {
        id: 1,
        img: img1,
        title: 'Contact Us',
        desc: 'Please feel free to contact us directly to info@ikhnuden.mn'
    },
    {
        id: 2,
        img: img3,
        title: 'Meeting',
        desc: 'We will introduce you to the full capabilities of IKHNUDEN system.'
    },
    {
        id: 3,
        img: img2,
        title: 'Negotiation',
        desc: 'We will discuss your goals thoroughly and agree on how to support your businesses.'
    },
    {
        id: 4,
        img: img4,
        title: 'Become a Partner',
        desc: 'We will support you make your businesses successful in short time.'
    },


]

export default dataPortfolio;